/* Styles go here */
/* img {
  border: 0px solid #000;
  border-radius: 4px;
  padding: 0px;
  width: 850px;
} */
/* .dog {
  background-color: lightgreen;
}


@media (max-width: 400px) {
  .dog {
    background-color: lightblue;
  }
} */
.artistNames{
  margin-bottom: 2px;
}
.imgCurr {
  float: right;
  margin: 0 0 0 15px;
  padding: 5pt;
  padding-top: 0px;
  width:53%;
}
.drop {
  text-shadow: 1px 2px 2px #000000;
  text-align: center;
  margin: auto;
  font-size:2.5vw;
}
.imgDetFloat{
  float: right;
  margin: 0 0 0 10px;
  padding: 5pt;
  width:55%;
}
.half-width-detail{
  width:42%;
  margin-left: 0;
}
.half-width{
  width:37%;
  margin-left: 0;
}
.imgCont h1{
  font-weight: bold;
  /* color: #000; */
  font-size: 12.5vw;
  color: #ffffff;

}
.imgCont h3 {
  /* color: #000; */
  font-size: 3vw;
  color: #ffffff;

}
.collDescription{
  color: grey;
  font-weight: lighter;
  margin-top: 0px;
  margin-bottom: 2px;
}
.bbb{
  font-size: 2px;
}
@media (max-width: 500px) {
  .imgCurr {
    float: none;
    width: 95%;
    margin: auto;
  }
  .collDescription{
    font-size: 19pt;

  }
  .drop {
    font-size:5vw;
    text-align: center;

  }
  .imgDetFloat{
    float: none;
    margin: 0 0 0 0px;
    padding: 5pt;
    width:98%;

  }
  .half-width-detail{
    width:95%;
    margin-left: 0;
  }
  .half-width{
    width:95%;
    margin-left: 0;
  }
}
/* .container {
  max-width: 30%;
  background-color: blue;


} */
@media (min-width: 900px) {
  .coca {
    max-width: 70%;
    margin: auto;

  }
}
/* @media (min-width: 800px) {
  .coo {
    max-width: 200px;
    background-color: green;


  }
} */

@media (min-width: 900px) {
  .imgCont h1{
    font-size: 80pt;


  }
  .imgCont h3 {
    /* color: #000; */
    font-size: 22pt;

  }
}
@media (min-width: 1500px) {
  .imgCont h1{
    font-size: 140pt;


  }
  .imgCont h3 {
    /* color: #000; */
    font-size: 42pt;

  }
}

.currWhole {

}
.currWhole * not(.drop){
  text-align: left;
}

@media (max-width: 500px) {
  .currWhole {
    margin-top: 4px;

  }
  .currWhole * {
    text-align: center;
  }
}


.contPadding{
  padding-top: 0px;
  padding-left: 2px;
  padding-right: 2px;

}


.formProd {
  max-width: 200px;
  margin: 0 auto;
}
.animation {
  animation: rotation 8s infinite linear;
}
.refText{
  margin-top: auto;
  margin-bottom: auto;
  color: grey;
  font-weight: lighter;
  cursor:pointer;

}

.detailText{
  overflow-wrap: break-word;
  overflow: hidden;
  clear: both;

}
.releaseDescription{
  color: grey;
  font-weight: lighter;
  text-align: center;
  margin-top: 12px;


}



/* responsive, form small screens, use 13px font size */






.belowProj{
  /* color: #000000; */
  margin: 5pt;
  color: grey;
  /* font-weight: lighter; */
  margin-bottom: 7pt;

}
.detImage{
  width: 100%;
  display: block;
  padding-top: 7px;
  cursor: pointer;


}

.block-icon {
  position: relative;
  display: inline-flex;
}

.icon-tag {
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 12px !important;
  height: 12px !important;
}
.MuiImageListItemBar-root{
    background-color: rgba(222, 0, 0, 0.9);
    color: #ff88aa;
}
.curr{
  color: #aaaaaa;
}
.ital {
  font-style: italic;
}
.oo {
  overflow: hidden;
}
.allproj{
  margin-top: 25pt;
}
.imgAll {
  object-fit: contain;
  width:210pt;
  height:210pt;
  margin-left: 50%;
  /* Move to the left by 50% of own width */
  transform: translateX(-50%);
}

.project {
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
   display: inline-block;
}
.black {
  color:#000000;
}
.linkColor {
  cursor: pointer;
  color: grey;
  /* color: #0000ff; */
  font-size: 12px;
}
.expandColor{
  cursor: pointer;
  color: #999999;
  font-size: 12px;
}
.right{
   /* float:right; */
   color: #ff0000;

}
.galDiv {
    /* width: 100%;
    overflow: hidden;
    white-space: nowrap; */
    display: flex;
    justify-content: space-between;
}
.nu {
  white-space: pre-wrap;

}
.desc {
  width: 85%;
  margin: auto;

}

.textGallery {
  /* display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
}




.modal {
  background-color: #333333dd;
  /* margin:0px; */
}
.imgMod {
  width:100%;
}
.custom-modal{
  background-color: #333333;
}
.cusMo{
  background-color: #000000;
}


.modal-content {




}
.ma {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 }
.a, .b, .c {
  display: flex;
 }
.b {
  text-align: center;
  align-content: center;
}
.c {
  position: absolute;

  right: 0;


}



.boldIt {
  font-weight: bold;

  text-transform: capitalize;

}
.justBold {
  font-weight: bold;
}
.darkB{
  background-color: grey;
}
.buttonDark {
  background-color: black;
  color: white;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 3px;
  margin: 7px 9px;
  margin-left: 11px;
  float: right;

}


.topRef{
  max-width:30px;
  max-height:auto;
  margin: 8px;
  margin-right: 15px;
  cursor:pointer;
}
.wrap {
    overflow-wrap: anywhere;
  }
.butt {
  margin-bottom: 7px;
}
.food {
    outline: 2px solid black;
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: 350px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 15px;

}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.withPadding{
  margin-top: 55px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  padding-top:20px
}
.justBlack{
  color: #000000;
}
.infoText {
  text-align: center;
  margin-top: 20px;

}

.token {
  height: 150px;
  width: 150px;
   border-radius: 50%;
   display: inline-block;
   background-color: #000000;
}

#banner {
  width: 100%;
  /* object-fit:contain;
  max-height: 100px;
  overflow: hidden; */
  object-position: center; /* Center the image within the element */
  -webkit-transform: translate(0%,-18%);
      -ms-transform: translate(0%,-18%);
          transform: translate(0%,-18%);
}

#toper {
  width: 100%;
  overflow: hidden;
  margin-top: -5px;

  /* background-image: image(); */

}
.center {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.foo{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;


}
.topmar{
  margin-top: 26px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.wrapit{
  word-wrap: break-word;
  word-break:break-all;
}
.margey{
  margin-top:10px;
  margin-left:auto;
  margin-right:auto;
}
#verytop {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  background-size:100%;
  padding-bottom: 1vw;
}
.addspace {
  margin-bottom: 0px;
  margin-top:2px;
}
p.content {

}
.imz {
  height: 100%;
}
.navbar-brand{
  color: white !important;
  font-size: 2.6vw;
}
#guys{
  color: #ffffff;
}
.col-centered{
    float: none;
    margin: 0 auto;
}
